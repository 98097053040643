import React from "react";
import {Header} from "./header";
import {Footer} from "./footer";
import {Container} from "react-bootstrap";

export function Layout(props) {
    return (
        <Container>
            <Header/>
            {props.children}
            <hr/>
            <Footer/>
        </Container>
    )
}