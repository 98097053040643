import {useNavigate} from "react-router-dom";
import {paths} from "../constants";
import {Navbar, Nav} from "react-bootstrap";
import React from "react";

export function Header() {
    const navigate = useNavigate();
    return (
        <Navbar expand="lg">
            <Navbar.Brand onClick={() => navigate(paths.root)}>Jordan Liu</Navbar.Brand>
            <Navbar.Toggle/>
            <Navbar.Collapse>
                <Nav>
                    <Nav.Link onClick={() => navigate(paths.root)}>Home</Nav.Link>
                    <Nav.Link onClick={() => navigate(paths.projects)}>Projects</Nav.Link>
                    <Nav.Link onClick={() => navigate(paths.about)}>About me</Nav.Link>
                    {/*<Nav.Link onClick={() => navigate(paths.resume)}>Experience</Nav.Link>*/}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}