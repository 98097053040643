import React from "react";
import {Nav} from "react-bootstrap";

export function Footer() {
    return (
        <Nav>
            <Nav.Item>
                <Nav.Link href="https://linktr.ee/jordanliu">linktr.ee/jordanliu</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}