import {Layout} from "../components/layout";
import {Col, Container, Row} from "react-bootstrap";

export function ResumePage(props) {
    return (
        <Layout>
            <Container fluid>
                <Row>
                    <h1>Text</h1>
                </Row>
                <Row>
                    <Col>1 of 1</Col>
                </Row>
                <Row>
                    <h1>Text</h1>
                </Row>
                <Row>
                    <Col>1 of 1</Col>
                </Row>
            </Container>
        </Layout>
    )
}