import {Layout} from "../components/layout";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import React from "react";

export function ProjectsPage(props) {
    const items = [
        {
            url: "https://medium.com/@liu.jordan.com/test-blog-b991dc683208",
            title: "This website",
            description: "Use react and github actions to build and deploy a production ready website in a full CI/CD environment at zero cost",
            image: "https://www.shutterstock.com/image-illustration/devsecops-concept-integration-security-testing-600nw-2158916631.jpg"
        }
    ];
    return (
        <Layout>
            <Container fluid>
                <Row>
                    {items.map((item) => (
                        <Col>
                            <Card style={{width: '18rem'}}>
                                {
                                    item.image
                                        ? <Card.Img variant="top" src={item.image}/>
                                        : undefined
                                }
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.description}</Card.Text>
                                    <Button variant="primary" href={item.url} target="_blank">Read</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Layout>
    )
}