import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function ValentinesDayPage(props) {
    let confettiOverlays = [
        "https://media2.giphy.com/media/1isEkdhbL9A9TTtVUB/giphy.webp?cid=790b7611jgg4n4d3v8m2twspfdtyuinrydbertqpk8eusi7h&ep=v1_gifs_search&rid=giphy.webp&ct=s",
        "https://media1.giphy.com/media/26BRABnerqonwLHMc/giphy.webp?cid=790b7611jgg4n4d3v8m2twspfdtyuinrydbertqpk8eusi7h&ep=v1_gifs_search&rid=giphy.webp&ct=s",
    ]
    let happyFiles = [
        "chipi_chipi_cat.mp4",
        "Cat dancing to girlfriend _ Dancing cat hey hey you you.mp4",
        "Cat dancing trending meme green screen.mp4",
        "Happy  Cat Meme Green Screen.mp4"
    ];
    let sadFiles = [
        "huh_cat.mp4",
        "Banana cat crying green screen meme.mp4",
        "Cat Hits Another Cat Meme Green Screen.mp4",
        "Cat Zoning Out Meme  HD Green Screen.mp4",
        "Green Screen Dramatic Kitten Meme _ Screaming Cat Meme.mp4",
        "Green Screen Sad Meowing Cat Meme.mp4"
    ];
    let { width, height } = useWindowDimensions()
    let [displayVideo, setDisplayVideo] = useState("");
    let videoRef = useRef<HTMLVideoElement>();
    let [sadFileIndex, setSadFileIndex] = useState(0);
    let [happyFileIndex, setHappyFileIndex] = useState(0);
    let [isConfetti, setIsConfetti] = useState(false);
    let handleNoButtonOnClick = (e: React.MouseEvent<HTMLElement>) => {
        let newIndex = (sadFileIndex + 1) % sadFiles.length
        if (newIndex === sadFileIndex) {
            return
        }
        setDisplayVideo(sadFiles[sadFileIndex]);
        setSadFileIndex(newIndex)
    }
    useEffect(() => {
        if (displayVideo !== "") {
            videoRef.current.load();
            videoRef.current.play();
        }
    }, [displayVideo]);
    let handleYesButtonOnClick = (e: React.MouseEvent<HTMLElement>) => {
        let newIndex = (happyFileIndex + 1) % happyFiles.length
        if (newIndex === happyFileIndex) {
            return
        }
        setDisplayVideo(happyFiles[happyFileIndex]);
        setHappyFileIndex(newIndex)
        setIsConfetti(true)
        videoRef.current.loop = false
        videoRef.current.onended = handleVideoOnEnded
    }
    let displayVideoFunc = () => {
        if (displayVideo !== "") {
            return <source src={process.env.PUBLIC_URL + "/" + displayVideo} type="video/mp4"/>
        }
        return null;
    }

    let handleVideoOnEnded = (e) => {
        setHappyFileIndex((idx) => {
            let newIndex = (idx + 1) % happyFiles.length;
            setDisplayVideo(happyFiles[newIndex]);
            console.log(newIndex)
            return newIndex
        })
    }

    document.body.style.backgroundColor = "beige";

    let confetti = (url) => {
        if (isConfetti) {
            return <img alt="" style={{position: 'absolute', top: 0, left: 0, width: width, height: height}} src={url}/>
        }
    }
    return <Container fluid={"sm"}>
        <Row>
            <Col className="d-flex" style={{justifyContent: "center"}}>
                <h1 style={{color: "magenta", fontFamily: "Georgia", fontSize: "4rem"}}>
                    {isConfetti
                        ? "SHE SAID YESS!"
                        :"Will you be my valentine?"}</h1>
            </Col>
        </Row>
        <Row>
        <Col  className="d-flex" style={{justifyContent: "center"}}>
                <video width={width*0.8} height={height*0.8} loop ref={videoRef}>
                    {displayVideoFunc()}
                </video>
            </Col>
        </Row>
        <Row>
            <Col>
                {
                    !isConfetti
                    ?   <div  className="d-flex" style={{gap: "13px" ,justifyContent: "center"}}>
                            <Button onClick={handleYesButtonOnClick}>Yes</Button>
                            <Button onClick={handleNoButtonOnClick}>No</Button>
                        </div>
                    : null
                }
            </Col>
        </Row>
        {confettiOverlays.map(url => confetti(url))}
        {isConfetti
        ? <img
                alt="" src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExZmcyMHZuY3czZGg0ODdlZmpscDRxZHkwN3lieHRmdGt3ejBqb3o2ZyZlcD12MV9naWZzX3NlYXJjaCZjdD1z/XmWLfZZxudBjl4J15T/giphy.webp"
            style={{position: 'absolute', top: 0, left: 0, width: width, height: "50%"}} />
        : null
        }
        {isConfetti
        ? <img alt="" src="https://media2.giphy.com/media/pDtIsbGHpEgP5QgCXj/giphy.webp?cid=790b76119aih8mtl82idn0jp7nyn0ps9qhr8qvt9xulhdrfd&ep=v1_gifs_search&rid=giphy.webp&ct=s"
            style={{position: 'absolute', bottom: 0, left: 0, }}/>
        : null}

        {isConfetti
            ? <img alt="" src="https://media2.giphy.com/media/pDtIsbGHpEgP5QgCXj/giphy.webp?cid=790b76119aih8mtl82idn0jp7nyn0ps9qhr8qvt9xulhdrfd&ep=v1_gifs_search&rid=giphy.webp&ct=s"
                   style={{position: 'absolute', bottom: 0, right: 0, }}/>
            : null}
    </Container>
}