import {Layout} from "../components/layout";
import {Col, Container, Row} from "react-bootstrap";

export function AboutPage(props) {
    return (
        <Layout>
            <Container fluid="md">
                <Row>
                    <Col align="center">
                        <img alt="" src={process.env.PUBLIC_URL + '/1541393331384.jfif'}/>
                    </Col>
                    <Col md>
                        <div>
                            <p>Hello!</p>
                            <p>
                                I’m Jordan Liu, a passionate software developer based in Vancouver. With a strong
                                background
                                in backend
                                development, I thrive on creating innovative solutions that enhance user experience and
                                drive
                                business success.
                            </p>

                            <p>
                                Outside of coding, I enjoy exploring the latest trends in technology and design. I’m an
                                avid
                                reader of tech blogs and love participating in online forums to share insights and learn
                                from others in the field. Additionally, I value teamwork and collaboration, often
                                engaging
                                in
                                community hackathons and coding challenges to sharpen my skills and connect with
                                like-minded
                                professionals.
                            </p>

                            <p>
                                I believe in continuous learning and am always on the lookout for opportunities to
                                expand my
                                knowledge and contribute positively to my projects. Let’s connect!
                            </p>
                        </div>

                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}